// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
  padding: 20px;
  background-color: #333;
  color: #fff;
  text-align: center;
}
footer p {
  margin: 0;
  font-size: 16px;
  font-family: "Roboto Mono", monospace;
}`, "",{"version":3,"sources":["webpack://./src/styles/Footer.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,WAAA;EACA,kBAAA;AAAJ;AAEI;EACE,SAAA;EACA,eAAA;EACC,qCCVM;ADUb","sourcesContent":["@import './variables.scss';\n\nfooter {\n    padding: 20px;\n    background-color: #333;\n    color: #fff;\n    text-align: center;\n  \n    p {\n      margin: 0;\n      font-size: 16px; \n       font-family: $font_family;\n\n\n    }\n  }\n  ","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
