// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.skills-container {
  padding: 50px;
  background: #333;
  text-align: center;
}

.skills-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #f39c12;
  font-family: "Roboto Mono", monospace;
}

.skills-list {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 4rem;
  color: #f39c12;
}
.skill:hover {
  color: white;
}

.skill-name {
  font-size: 1.2rem;
  margin-top: 10px;
  color: #f39c12;
}`, "",{"version":3,"sources":["webpack://./src/styles/Skills.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,gBAAA;EACA,kBAAA;AAAJ;;AAGE;EACE,iBAAA;EACA,mBAAA;EACA,cAAA;EACA,qCCXS;ADWb;;AAIE;EACE,aAAA;EACA,uBAAA;EACA,SAAA;EACA,gBAAA;AADJ;;AAIE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;AADJ;AAEI;EACE,YAAA;AAAN;;AAIE;EACE,iBAAA;EACA,gBAAA;EACA,cAAA;AADJ","sourcesContent":["@import './variables.scss';\n\n.skills-container {\n    padding: 50px;\n    background: #333;\n    text-align: center;\n  }\n  \n  .skills-title {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n    color: #f39c12;\n    font-family: $font_family;\n\n  }\n  \n  .skills-list {\n    display: flex;\n    justify-content: center;\n    gap: 40px;\n    margin-top: 30px;\n  }\n  \n  .skill {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    font-size: 4rem;\n    color: #f39c12;\n    &:hover{\n      color: white;\n    }\n  }\n  \n  .skill-name {\n    font-size: 1.2rem;\n    margin-top: 10px;\n    color: #f39c12;\n   \n  }\n  ","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
