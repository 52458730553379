// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-introduction {
  padding: 20px;
  color: #f39c12;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 2px solid #f39c12;
}
.blog-introduction h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: "Roboto Mono", monospace;
}
.blog-introduction p {
  font-size: 1.2rem;
  margin-bottom: 20px;
  color: black;
  font-family: "Roboto Mono", monospace;
}
.blog-introduction .blog-link {
  font-size: 1.2rem;
  color: #f39c12;
  text-decoration: none;
  border: 2px solid #f39c12;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Roboto Mono", monospace;
}
.blog-introduction .blog-link:hover {
  background-color: #f39c12;
  color: black;
}`, "",{"version":3,"sources":["webpack://./src/styles/BlogIntroduction.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,cAAA;EACA,kBAAA;EACA,0CAAA;EACA,yBAAA;AACJ;AACI;EACE,iBAAA;EACA,mBAAA;EACA,qCCVO;ADWb;AAGI;EACE,iBAAA;EACA,mBAAA;EACA,YAAA;EACA,qCClBO;ADiBb;AAKI;EACE,iBAAA;EACA,cAAA;EACA,qBAAA;EACA,yBAAA;EACA,kBAAA;EACA,kBAAA;EACA,6CAAA;EACA,qCC9BO;AD2Bb;AAMM;EACE,yBAAA;EACA,YAAA;AAJR","sourcesContent":["@import './variables.scss';\n.blog-introduction {\n    padding: 20px;\n    color: #f39c12;\n    text-align: center;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    border:2px solid #f39c12;\n    \n    h2 {\n      font-size: 2.5rem;\n      margin-bottom: 20px;\n      font-family: $font_family;\n\n    }\n  \n    p {\n      font-size: 1.2rem;\n      margin-bottom: 20px;\n      color:black;\n      font-family: $font_family;\n\n    }\n  \n    .blog-link {\n      font-size: 1.2rem;\n      color: #f39c12;\n      text-decoration: none;\n      border: 2px solid #f39c12;\n      padding: 10px 20px;\n      border-radius: 5px;\n      transition: background-color 0.3s, color 0.3s;\n      font-family: $font_family;\n\n  \n      &:hover {\n        background-color: #f39c12;\n        color:black;\n      }\n    }\n  }\n  ","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
