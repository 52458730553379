// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.aboutme-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: white;
  color: black;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border: 3px solid #f39c12;
  margin: 30px;
}

.aboutme-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.aboutme-content {
  font-size: 1.5rem;
  max-width: 800px;
  color: black;
  font-family: "Roboto Mono", monospace;
}`, "",{"version":3,"sources":["webpack://./src/styles/AboutMe.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AACA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,0CAAA;EACA,yBAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,mBAAA;AACF;;AAIA;EACE,iBAAA;EACA,gBAAA;EACA,YAAA;EACA,qCC1BW;ADyBb","sourcesContent":["@import './variables.scss';\n.aboutme-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  padding: 50px;\n  background-color: white;\n  color: black;\n  text-align: center;\n  border-radius: 10px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n  border:3px solid #f39c12 ;\n  margin: 30px;\n}\n\n.aboutme-title {\n  font-size: 2.5rem;\n  margin-bottom: 20px;\n}\n\n \n\n.aboutme-content {\n  font-size: 1.5rem;\n  max-width: 800px;\n  color: black;\n  font-family: $font_family;\n}\n","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
