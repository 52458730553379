// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  height: 400px; /* Increase the height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #333;
  color: #f39c12;
}
.header .header-content {
  text-align: center;
}
.header .typing-animation {
  font-size: 2.5rem;
  white-space: nowrap;
  overflow: hidden;
  border-right: 4px solid white; /* Cursor effect */
  width: 0; /* Start with 0 width */
  animation: typing 2s steps(22) forwards, blink 0.75s step-end infinite;
}
.header p.typing-animation {
  font-size: 1.5rem;
  margin-top: 10px;
}
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink {
  from, to {
    border-color: transparent;
  }
  50% {
    border-color: white;
  }
}`, "",{"version":3,"sources":["webpack://./src/styles/Header.scss"],"names":[],"mappings":"AAAA;EACE,aAAA,EAAA,wBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;AACJ;AAEE;EACE,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,6BAAA,EAAA,kBAAA;EACA,QAAA,EAAA,uBAAA;EACA,sEAAA;AAAJ;AAGE;EACE,iBAAA;EACA,gBAAA;AADJ;AAIE;EACE;IAAO,QAAA;EADT;EAEE;IAAK,WAAA;EACP;AACF;AACE;EACE;IAAW,yBAAA;EAEb;EADE;IAAM,mBAAA;EAIR;AACF","sourcesContent":[".header {\n  height: 400px; /* Increase the height */\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background-color: #333;\n  color: #f39c12;\n\n  .header-content {\n    text-align: center;\n  }\n\n  .typing-animation {\n    font-size: 2.5rem;\n    white-space: nowrap;\n    overflow: hidden;\n    border-right: 4px solid white; /* Cursor effect */\n    width: 0; /* Start with 0 width */\n    animation: typing 2s steps(22) forwards, blink 0.75s step-end infinite;\n  }\n\n  p.typing-animation {\n    font-size: 1.5rem;\n    margin-top: 10px;\n  }\n\n  @keyframes typing {\n    from { width: 0; }\n    to { width: 100%; }\n  }\n\n  @keyframes blink {\n    from, to { border-color: transparent; }\n    50% { border-color: white; }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
