// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-container {
  padding: 50px;
  background-color: #333;
  color: #f39c12;
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.contact-container h2 {
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-family: "Roboto Mono", monospace;
}
.contact-container .contact-details {
  display: flex;
  justify-content: center;
  align-items: center;
}
.contact-container .json-format {
  font-family: "Courier New", Courier, monospace;
  font-size: 1.2rem;
  background-color: #1e1e1e;
  color: #f39c12;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: left;
}
.contact-container .json-format a.mail-link {
  color: #f39c12;
  text-decoration: none;
  transition: color 0.3s ease;
}
.contact-container .json-format a.mail-link:hover {
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/styles/Contact.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAGA;EACE,aAAA;EACA,sBAAA;EACA,cAAA;EACA,kBAAA;EACA,0CAAA;AADF;AAGE;EACE,iBAAA;EACA,mBAAA;EACA,qCCZS;ADWb;AAKE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AAHJ;AAME;EACE,8CAAA;EACA,iBAAA;EACA,yBAAA;EACA,cAAA;EACA,aAAA;EACA,kBAAA;EACA,0CAAA;EACA,gBAAA;AAJJ;AAMI;EACE,cAAA;EACA,qBAAA;EACA,2BAAA;AAJN;AAMM;EACE,YAAA;AAJR","sourcesContent":["// Contact.scss\n@import './variables.scss';\n\n.contact-container {\n  padding: 50px;\n  background-color: #333;\n  color: #f39c12;\n  text-align: center;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n\n  h2 {\n    font-size: 2.5rem;\n    margin-bottom: 20px;\n    font-family: $font_family;\n\n  }\n\n  .contact-details {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n\n  .json-format {\n    font-family: 'Courier New', Courier, monospace;\n    font-size: 1.2rem;\n    background-color: #1e1e1e;\n    color: #f39c12;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);\n    text-align: left;\n\n    a.mail-link {\n      color: #f39c12;\n      text-decoration: none;\n      transition: color 0.3s ease;\n\n      &:hover {\n        color: white;\n      }\n    }\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
