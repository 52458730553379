// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog-list-container {
  padding: 30px;
  border-radius: 10px;
  background-color: #222;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  color: #f39c12;
  max-width: 800px;
  margin: 0 auto;
}

.blog-list {
  list-style: none;
  padding: 0;
}

.blog-item {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #333;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.blog-item:hover {
  transform: translateY(-5px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);
}

h3 {
  margin: 0 0 15px;
  color: #f39c12;
  font-size: 1.8rem;
}

p {
  margin: 0 0 15px;
  color: #ccc;
  line-height: 1.6;
  font-family: "Roboto Mono", monospace;
}

.expand-button {
  background-color: #333;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.expand-button:hover {
  color: #e67e22;
}`, "",{"version":3,"sources":["webpack://./src/styles/BlogList.scss","webpack://./src/styles/variables.scss"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,2CAAA;EACA,cAAA;EACA,gBAAA;EACA,cAAA;AAAF;;AAGA;EACE,gBAAA;EACA,UAAA;AAAF;;AAGA;EACE,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,2CAAA;EACA,qDAAA;AAAF;AAEE;EACE,2BAAA;EACA,2CAAA;AAAJ;;AAIA;EACE,gBAAA;EACA,cAAA;EACA,iBAAA;AADF;;AAIA;EACE,gBAAA;EACA,WAAA;EACA,gBAAA;EACA,qCCxCW;ADuCb;;AAKA;EACE,sBAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;AAFF;AAIE;EACE,cAAA;AAFJ","sourcesContent":["@import './variables.scss';\n\n.blog-list-container {\n  padding: 30px;\n  border-radius: 10px;\n  background-color: #222;\n  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);\n  color: #f39c12;\n  max-width: 800px;\n  margin: 0 auto;\n}\n\n.blog-list {\n  list-style: none;\n  padding: 0;\n}\n\n.blog-item {\n  margin-bottom: 20px;\n  padding: 20px;\n  background-color: #333;\n  border-radius: 8px;\n  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);\n  transition: transform 0.3s ease, box-shadow 0.3s ease;\n\n  &:hover {\n    transform: translateY(-5px);\n    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.3);\n  }\n}\n\nh3 {\n  margin: 0 0 15px;\n  color: #f39c12;\n  font-size: 1.8rem;\n}\n\np {\n  margin: 0 0 15px;\n  color: #ccc;\n  line-height: 1.6;\n  font-family: $font_family;\n\n}\n\n.expand-button {\n  background-color: #333;\n  color: white;\n  border: none;\n  padding: 8px 15px;\n  border-radius: 5px;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n\n  &:hover {\n    color: #e67e22;\n  }\n}\n","@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Quicksand:wght@300..700&family=Roboto+Mono:ital,wght@0,100..700;1,100..700&display=swap');\n$font_family:\"Roboto Mono\", monospace"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
